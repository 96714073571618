<template>
  <!-- 违规 -->
  <div class="grid-content">
    <div class="equipmentText">
      <div class="title" @click="goViolaList">
        <span class="titles">{{ $t("home.top.vioTotal") }}</span><span class="unit" style="color: black;cursor: pointer"><big class="zSize" style=" margin-right: 10px;cursor: pointer">{{
                  violationChild.violationSum || "0"
                }}</big>{{ $t("home.top.throughs") }}</span>
      </div>
      <div class="strip">
        <div class="offline" @click="goViolaSum">
          <el-progress :percentage="100" :show-text="false" color="#D7E7F7"></el-progress>
        </div>
        <div @click.stop="goIntraday" :style="{ width: ViolationCountPer }" class="online">
          <el-progress :percentage="100" :show-text="false" color="#EE6666"></el-progress>
        </div>
      </div>
      <div class="percenText">
        <span style="color: #d9001b" @click="goViolation">{{ $t("home.top.todayViolationCount") }}
          {{ violationChild.todayViolationCount || "0" }}</span>
        <span style="color: #9b9b9b" @click="goViolaSum">{{ $t("home.top.total") }}
          {{ violationChild.violationSum || "0" }}</span>
      </div>
    </div>
    <div class="equipmentPic" @click="goViolaList" style="cursor: pointer">
      <img class="image" src="../../../../images/home/violation.png" @click="goViolaList" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    violationChild: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      saveAllList: [] //批量vuex中保存的菜单数组
    }
  },
  computed: {
    ViolationCountPer() {
      let violaWidth =
        (this.violationChild.todayViolationCount /
          this.violationChild.violationSum) *
        100
      if (violaWidth > 100) {
        violaWidth = 100
        return violaWidth + '%'
      }
      return violaWidth + '%'
    }
  },
  mounted() {
    setTimeout(() => {
      this.saveAllList = [...this.$store.state.selectList]
      // console.log(this.saveAllList, '--this.saveAllList路由')
    }, 800)
  },
  methods: {
    //点击跳转到违规列表
    goViolaList() {
      var timeString = this.$common.cstDateFormat(
        this.violationChild.startTime,
        'yyyy-MM-dd'
      )
      //判断一级 用户管理 名称是否能找到
      let ViolationName = this.$store.state.selectList.find(item=>item.name=="SecurityMonitor")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(ViolationName?.name){
        ViolationName.children.forEach((element) => {
          if (element.name.indexOf('ViolationMonitor') != -1) {
            this.$router.push({
              name: 'ViolationMonitor',
              query: {
                flag: true,
                startTime: timeString
              }
            })
          } else {
            return false
          }
        })
      }
      // this.saveAllList.forEach((element) => {
      //   if (element.name.indexOf('SecurityMonitor') !== -1) {
      //     this.$router.push({
      //       name: 'ViolationMonitor',
      //       query: {
      //         flag: true,
      //         startTime: timeString
      //       }
      //     })
      //   } else {
      //     return false
      //   }
      // })
    },
    //点击违规总数
    goViolaSum() {
      this.goViolaList()
    },
    //点击当天
    goViolation() {
      var newTime = moment().format('YYYY-MM-DD')
      //判断一级 用户管理 名称是否能找到
      let ViolationName = this.$store.state.selectList.find(item=>item.name=="SecurityMonitor")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(ViolationName?.name){
        ViolationName.children.forEach((element) => {
          if (element.name.indexOf('ViolationMonitor') != -1) {
            this.$router.push({
              name: 'ViolationMonitor',
              query: {
                flag: 2,
                startTime: newTime
               }
            })
          } else {
            return false
          }
        })
      }




      // //跳转页面
      // var newTime = moment().format('YYYY-MM-DD')
      // this.saveAllList.forEach((element) => {
      //   if (element.name.indexOf('SecurityMonitor') !== -1) {
      //     this.$router.push({
      //       name: 'ViolationMonitor',
      //       query: {
      //         flag: 2,
      //         startTime: newTime
      //       }
      //     })
      //   } else {
      //     return false
      //   }
      // })
    },
    //点击当天进度条
    goIntraday() {
      this.goViolation()
    }
  }
}
</script>

<style lang="scss" scoped>
.equipmentText {
  .title {
    height: 25%;
  }
  .strip {
    height: 10%;
    margin: 1.375rem 0rem !important;
    width: 100%;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    position: relative;
    .online {
      cursor: pointer;
      height: 0.375rem;
      position: absolute;
      top: 0rem;
      left: 0rem;
    }
    .outService {
      position: absolute;
      top: 0rem;
      left: 0rem;
      z-index: inherit;
      cursor: pointer
    }
    .outService {
      position: absolute;
      top: 0rem;
      left: 0rem;
      z-index: inherit;
    }
    .offline{
      cursor: pointer
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 2040px) {
  // 违规总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 3%;
  }
  // 违规 数 字体大小
  .zSize {
    font-size: 22px;
  }
  // 默认单位18
  .unit {
    font-size: 18px;
  }
  .titles {
    font-size: 20px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  .percenText {
    height: 25%;
    span {
      font-size: 16px;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  // 违规总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 3%;
  }
  // 违规 数 字体大小
  .zSize {
    font-size: 22px;
  }
  // 默认单位18
  .unit {
    font-size: 18px;
  }
  .titles {
    font-size: 18px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  .percenText {
    height: 25%;
    span {
      font-size: 16px;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 1679px) {
  // 违规总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 3%;
  }
  // 违规 数 字体大小
  .zSize {
    font-size: 22px;
  }
  // 默认单位18
  .unit {
    font-size: 18px;
  }
  .titles {
    font-size: 18px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  .percenText {
    height: 25%;
    span {
      font-size: 16px;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 违规总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 5%;
    margin-left: 2%;
  }
  // 违规 数 字体大小
  .zSize {
    font-size: 30px;
  }
  // 违规总数  字体大小
  .titles {
    font-size: 26px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  // 默认单位22
  .unit {
    font-size: 18px;
  }
  // 图表 间隙 占比
  .equipmentPic {
    margin-right: 5%;
  }
  // 图片大小 占比
  .image {
    margin-top: 27%;
    width: 127%;
  }
  .percenText {
    height: 25%;
    span {
      // 正常 锁定  停用字体大小
      font-size: 20px;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
</style>
