<template>
  <div class="grid-content">
    <div class="title" style="color: #8a909e">
      <div class="titleFlog"></div>
      {{ $t("home.middle.PlatformContentStatistics") }}
    </div>
    <div class="stat">
      <ul>
        <li @click="goAppList" style="cursor: pointer">
          <div class="box">
            <div class="txt">{{ $t("home.middle.appSum") }}</div>

            <span class="sum">{{ contentStatistics.appSum || "0" }}</span>
          </div>
          <div><img src="../../../../images/home/strategy.png" alt="" /></div>
        </li>
        <!-- 点击策略总数跳转到策略列表@click="goPolicyList" -->
        <li @click="goPolicyList" style="cursor: pointer">
          <div class="box">
            <div class="txt">
              {{ $t("home.middle.strategySum") }}
            </div>
            <span class="sum">{{ contentStatistics.strategySum || "0" }}</span>
          </div>
          <div><img src="../../../../images/home/app.png" alt="" /></div>
        </li>
      </ul>
      <ul>
        <li @click="goDocList" style="cursor: pointer">
          <div class="box">
            <div class="txt">
              {{ $t("home.middle.documentsSum") }}
            </div>
            <span class="sum">{{ contentStatistics.documentSum || "0" }}</span>
          </div>
          <div><img src="../../../../images/home/file.png" alt="" /></div>
        </li>
        <!-- 点击通知总数跳转到通知列表 -->
        <li @click="goNoticeList" style="cursor: pointer">
          <div class="box">
            <div class="txt">{{ $t("home.middle.noticeSum") }}</div>
            <span class="sum">{{ contentStatistics.noticeSum || "0" }}</span>
          </div>
          <div><img src="../../../../images/home/notic.png" alt="" /></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //平台内容统计
      contentStatistics: {},
      saveAllList: [] //批量vuex中保存的菜单数组
    }
  },
  mounted() {
    this.getContentSta() //获取平台内容统计
    setTimeout(() => {
      this.saveAllList = [...this.$store.state.selectList]
      // console.log(this.saveAllList, '--this.saveAllList路由')
    }, 800)
  },
  methods: {

    //获取平台内容统计
    async getContentSta() {
      var params
      params = {
        groupID: '1'
      }
      const res = await this.$axios.post(
        '/httpServe/getContentSta',
        params,
        true
      )
      this.contentStatistics = res.data
      // console.log(res.data, '内容统计')
    },
    //点击应用总数跳转到应用列表
    goAppList() {
      console.log(this.$store.state.selectList,"this.$store.state.selectList")
       //判断一级 应用管理 名称是否能找到
       let appArrName = this.$store.state.selectList.find(item=>item.name=="AppManage")
        if(appArrName?.name){
          appArrName.children.forEach((element) => {
            if (element.name === 'App') {
              this.$router.push({
                name: 'App',
                query: {
                  key: '6',
                  keyPath: ['6'],
                  falgKeypath: true,
                  fl: true
                }
              })
            } else {
              return false
            }  
          })
        }
        
    
    },
    //点击策略总数跳转到策略列表
    goPolicyList() {
        //判断一级 设备管理 名称是否能找到
        let phoneName = this.$store.state.selectList.find(item=>item.name=="DeviceManage")
        let DeviceName = phoneName.children.find(item=>item.name=="PolicyConfig")
        if(DeviceName?.name){
          DeviceName.children.forEach((element) => {
            if (element.name.indexOf('DevicePolicy') != -1) {
              this.$router.push({
                      name: 'DevicePolicy',
                      query: {
                        key: '2',
                        keyPath: ['2'],
                        falgKeypath: true,
                        fl: true
                      }
                    })
            } else {
              return false
            }
          })
        }
    },
    //点击文档总数跳转到文档列表
    goDocList() {
      //判断一级 内容管理 名称是否能找到
      var contentName = this.$store.state.selectList.find(item=>item.name=="ContentManage")
      contentName.children.forEach((element) => {
        if (element.name.indexOf('Document') != -1) {
          this.$router.push({
            name: 'document',
            query: {
              key: '6',
              keyPath: ['6'],
              falgKeypath: true,
              fl: true
            }
          })
        } else {
          return false
        }
      })
    },
    //点击通知总数跳转到通知列表
    goNoticeList() {
      //判断一级 内容管理 名称是否能找到
      var contentName = this.$store.state.selectList.find(item=>item.name=="ContentManage")
      contentName.children.forEach((element) => {
        if (element.name.indexOf('Notice') != -1) {
          this.$router.push({
            name: 'Notice'
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 平台内容统计4个方块外层div的line-height
  .box {
    line-height: 36px;
  }
  .title {
    width: 100%;
    height: 12%;
    font-size: 24px; //卡片title  字体字号
    // 卡片title 距离上边框占比
    margin-top: 1%;
    .timeTypeFlog {
      .timeType {
        //时间范围下拉框
        display: inline-block;
        width: 52px;
        font-size: 17.8px;
      }
      .el-select {
        width: 89px;
        margin-left: 5px;
        padding: 0rem;
        font-size: 4px;
      }
    }
    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
  .stat {
    width: 96%;
    height: 80%;
    ul {
      height: 45%;
      display: flex;
      justify-content: space-around;
      margin-top: 0.625rem;
      padding: 0.3125rem 0rem;

      li {
        width: 45%;
        // height: 5.625rem;
        padding: 1.25rem 0.3125rem;
        background: #f9fafe;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .txt {
          color: #b7bece;
          // 4个块块上面的字体大小
          font-size: 22px;
          span {
            font-size: 1.1rem;
            color: black;
          }
        }
        // 4个块块下面的数量 字体大小
        .sum {
          font-size: 22px;
        }
        // 图片 宽度
        img {
          width: 43px;
        }
      }
    }
  }
}
@media screen and (min-width: 1920px) and (max-width: 2040px) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title
    .timeTypeFlog {
      .timeType {
        //时间范围下拉框
        display: inline-block;
        width: 52px;
        font-size: 17.8px;
      }
      .el-select {
        width: 89px;
        margin-left: 5px;
        padding: 0rem;
        font-size: 4px;
      }
    }
    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
  .stat {
    width: 96%;
    height: 80%;
    ul {
      height: 45%;
      display: flex;
      justify-content: space-around;
      margin-top: 0.625rem;
      padding: 0.3125rem 0rem;

      li {
        width: 45%;
        // height: 5.625rem;
        padding: 1.25rem 0.3125rem;
        background: #f9fafe;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .txt {
          color: #b7bece;
          font-size: 18px;
          span {
            font-size: 1.1rem;
            color: black;
          }
        }
        img {
          width: 35px;
        }
      }
    }
  }
}

@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title
    .timeTypeFlog {
      .timeType {
        //时间范围下拉框
        display: inline-block;
        width: 52px;
        font-size: 16.8px;
      }
      .el-select {
        width: 89px;
        margin-left: 5px;
        padding: 0rem;
        font-size: 4px;
      }
    }
    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
  .stat {
    width: 96%;
    height: 80%;
    ul {
      height: 45%;
      display: flex;
      justify-content: space-around;
      margin-top: 0.625rem;
      padding: 0.3125rem 0rem;

      li {
        width: 45%;
        // height: 5.625rem;
        padding: 1.25rem 0.3125rem;
        background: #f9fafe;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .txt {
          color: #b7bece;
          font-size: 18px;
          span {
            font-size: 1.1rem;
            color: black;
          }
        }
        img {
          width: 32px;
        }
      }
    }
  }
}
@media screen and (max-width: 1679px) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title
    .timeTypeFlog {
      .timeType {
        //时间范围下拉框
        display: inline-block;
        width: 52px;
        font-size: 16.8px;
      }
      .el-select {
        width: 89px;
        margin-left: 5px;
        padding: 0rem;
        font-size: 4px;
      }
    }
    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
  .stat {
    width: 96%;
    height: 80%;
    ul {
      height: 45%;
      display: flex;
      justify-content: space-around;
      margin-top: 0.625rem;
      padding: 0.3125rem 0rem;

      li {
        width: 45%;
        // height: 5.625rem;
        padding: 1.25rem 0.3125rem;
        background: #f9fafe;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .txt {
          color: #b7bece;
          font-size: 18px;
          span {
            font-size: 1.1rem;
            color: black;
          }
        }
        img {
          width: 32px;
        }
      }
    }
  }
}
</style>
