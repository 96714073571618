<template>
  <el-dialog
    :append-to-body="true"
    title="提示信息"
    :visible.sync="dialogInfo"
    :show-close="true"
    :close-on-click-modal="true"
    :close-on-press-escape="false"
    :width="width"
    style="margin-top: 10%"
    id="success"
    :before-close="handleClose"
    center
  >
    <p style="color: #606266;font-size: 14px;">{{ useInfo }}</p>
    <p style="color: #606266;font-size: 14px;">{{ serveInfo }}</p>
    <span slot="footer" class="dialog-footer">
      <el-button
        size="small"
        @click="determine"
        type="primary"
        :disabled="isDisabled"
        >确定（{{ Sencond }}）</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogInfo: {
      type: Boolean,
      default: false
    },
    useInfo: {
      type: String,
      default: ""
    },
    serveInfo: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      Sencond: 5,
      isDisabled: false,
      time: null
    };
  },
  watch: { //保存成功的倒计时弹框
    Sencond(newval) {
      if (newval === 0) {
        window.clearInterval(this.time);
        this.handleClose();
      }
    },
    dialogInfo(val) {
      this.dialogInfo = val;
      if (val) {
        this.Sencond = 5
        this.time = setInterval(() => {
          this.Sencond -= 1;
        }, 1000);
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit("handleClose1", false);
    },
    determine() { // 确定事件回调
      this.handleClose();
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  text-align: left;
}
</style>
