<template>
  <div class="grid-content">
    <div class="title titles" style="color: #8a909e">
      <div>
        <div class="titleFlog"></div>
        {{ $t("home.middle.DeviceActiveTrend") }}
      </div>
      <div class="timeTypeFlog">
        <div class="timeType">{{$t("public.TimeFrame")}}</div>
        <el-select v-model="activeTimeType" size="mini" :placeholder="$t('home.middle.choose')" @change="onChangeActive">
          <el-option v-for="item in activeOptions" :key="item.value" :label="$t(`home.middle.${item.label}`)" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="devActive">
      <div id="scount"></div>
      <!-- v-if="!showFlag1" <div v-else-if="showFlag1" class="noData">
        暂无数据
      </div> -->
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'
export default {
  data() {
    return {
      showFlag1: false,
      activeOptions: [
        {
          value: '0',
          label: '当天',
          scope: 1
        },
        {
          value: '1',
          label: '近7天',
          scope: 7
        },
        {
          value: '2',
          label: '近30天',
          scope: 30
        },
        {
          value: '3',
          label: '近90天',
          scope: 90
        }
      ],
      //定义双向绑定设备活跃趋势中筛选的值value值
      activeTimeType: '1',
      //设备活跃趋势
      activeTime: {},
      //时间组
      dateGrop: [],
      //设备活跃行为趋势选择时间范围
      acScopeTimes: 7,
      gapTime: '1', //默认间隔时间----近七天
      saveAllList: [] //批量vuex中保存的菜单数组
    }
  },
  watch: {
    "$i18n.locale"(newVal,old) {
      console.log(newVal,old,'val,old');
      // this.CurLangMode=newVal
      //监听语言的变化，每次变动都要重新渲染图表
      this.getDeviceActive();
    },
  },
  mounted() {
    var enttime = moment().format('YYYY-MM-DD')
    var statiem = moment().subtract(6, 'days').format('YYYY-MM-DD')
    this.getdiffdate(statiem, enttime)
    setTimeout(() => {
      this.getDeviceActive()
      this.saveAllList = [...this.$store.state.selectList]
      // console.log(this.saveAllList, '--this.saveAllList路由')
    }, 800)
  },
  methods: {
    //获取两日期之间日期列表函数
    getdiffdate(stime, etime) {
      //初始化日期列表，数组
      var diffdate = new Array()
      var i = 0
      //开始日期小于等于结束日期,并循环
      while (stime <= etime) {
        diffdate[i] = stime

        //获取开始日期时间戳
        var stime_ts = new Date(stime).getTime()

        //增加一天时间戳后的日期
        var next_date = stime_ts + 24 * 60 * 60 * 1000

        //拼接年月日，这里的月份会返回（0-11），所以要+1
        var next_dates_y = new Date(next_date).getFullYear() + '-'
        var next_dates_m =
          new Date(next_date).getMonth() + 1 < 10
            ? '0' + (new Date(next_date).getMonth() + 1) + '-'
            : new Date(next_date).getMonth() + 1 + '-'
        var next_dates_d =
          new Date(next_date).getDate() < 10
            ? '0' + new Date(next_date).getDate()
            : new Date(next_date).getDate()

        stime = next_dates_y + next_dates_m + next_dates_d

        //增加数组key
        i++
      }
      this.dateGrop = diffdate
    },
    // 获取设备活跃趋势
    onChangeActive(v) {
      this.acScopeTimes = this.activeOptions[v].scope
      this.gapTime = v
      console.log(this.gapTime, "获取时间范围=================");
      var day = this.activeOptions[v].scope - 1
      var enttime = moment().format('YYYY-MM-DD')
      var statiem = moment().subtract(day, 'days').format('YYYY-MM-DD')
      this.getdiffdate(statiem, enttime)
      this.getDeviceActive()
    },
    //获取设备活跃趋势数据
    async getDeviceActive() {
      var params
      params = {
        groupID: '1',
        timeType: this.activeTimeType
      }

      const res = await this.$axios.post(
        '/httpServe/getDeviceActive',
        params,
        true
      )

      console.log(res, '设备活跃数据')

      if (this.acScopeTimes == 1) {
        this.acScopeTimes = 24
        this.dateGrop = [
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24'
        ]
      }
      let emptyOnArr = Array(this.acScopeTimes).fill(0)
      // // console.log(emptyOnArr, "当天数组=========");
      let newDateGrop1 = this.dateGrop //根据当前时间退出前7天的日期
      let arrOnTimes = res.data.onTimes?res.data.onTimes:emptyOnArr
      let ArrOnLine = res.data.onLine?res.data.onLine:emptyOnArr
      for (var v = 0; v < newDateGrop1.length; v++) {
        for (var u = 0; u < arrOnTimes.length; u++) {
          if (newDateGrop1[v] == arrOnTimes[u]) {
            //确定i的大小，即是其角标
            // emptyArr[i] = 4;
            emptyOnArr[v] = ArrOnLine[u]
          }
        }
      }
      res.data.onLine = emptyOnArr
      res.data.onTimes = this.dateGrop

      let emptyOffArr = Array(this.acScopeTimes).fill(0)
      let newDateGropOff = this.dateGrop //根据当前时间退出前7天的日期
      let arrOffTimes = res.data.offTimes?res.data.offTimes:emptyOffArr
      let ArrOffLine = res.data.offLine?res.data.offLine:emptyOffArr
      for (var v = 0; v < newDateGropOff.length; v++) {
        for (var u = 0; u < arrOffTimes.length; u++) {
          if (newDateGropOff[v] == arrOffTimes[u]) {
            //确定i的大小，即是其角标
            // emptyArr[i] = 4;
            emptyOffArr[v] = ArrOffLine[u]
          }
        }
      }
      res.data.offLine = emptyOffArr
      res.data.offTimes = this.dateGrop
      this.scount(res.data)
    },
    // 第一个折线图设备活跃趋势
    scount(activeTime) {
      console.log(activeTime, 'activeTime')
      var chartDom = document.getElementById('scount')
      var myChart = echarts.init(chartDom)
      let array1 = [...activeTime.onLine, ...activeTime.offLine]
      let max = Math.max.apply(null, array1)
      var interval = this.acScopeTimes == 7 ? 1 : this.acScopeTimes / 6
      var that = this
      var option
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              show: false
            }
          }
        },
        legend: {
          data: [this.$t("home.middle.inlineEquipment"), this.$t("home.middle.offlineEquipment")],
          icon: 'circle',
          itemWidth: 5,
          itemHeight: 5
        },
        grid: {
          left: '2%',
          right: '5%',
          bottom: '3%',
          containLabel: true
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: activeTime.onTimes,
          axisLabel: {
            // interval: 4 //加入axisLabel字段,interval后面加你想要间隔的个数
            interval: (index, value) => {
              if (index == 0) {
                return true
              }
              return (index + 1) % interval == 0
            }
          }
        },
        yAxis: {
          // category
          // type: "value"
          // Y轴线显示
          axisLine: { show: true },
          type: 'value',
          // 数据最大值最小值
          min: 0,
          max: max <= 10 ? 10 : max
        },
        series: [
          {
            name: this.$t("home.middle.inlineEquipment"),//在线设备
            type: 'line',
            data: activeTime.onLine,
            smooth: true, //设置曲线,
            itemStyle: {
              normal: {
                // 折线点颜色
                color: '#57DAEB',
                // 线的颜色
                lineStyle: {
                  color: '#57DAEB'
                }
              }
            }
          },
          {
            name: this.$t("home.middle.offlineEquipment"),//离线设备
            type: 'line',
            data: activeTime.offLine,
            smooth: true, //设置曲线,

            itemStyle: {
              normal: {
                // 折线点颜色
                color: '#3E8AF5',
                // 线的颜色
                lineStyle: {
                  color: '#3E8AF5'
                }
              }
            }
          }
        ]
      }
      myChart.on('click', function (params) {
        // 控制台打印数据name-----时间   value-----值
        console.log(params, that.acScopeTimes, '设备活跃趋势图')
        //1--在线  0---离线
        let conValue = ''
        if (params.seriesName == '离线设备') {
          conValue = '0'
        } else if (params.seriesName == '在线设备') {
          conValue = '1'
        }
        let conStateRate = ''
        if (that.gapTime == '0') {
          conStateRate = '1'
        } else if (that.gapTime == '1') {
          conStateRate = '7'
        } else if (that.gapTime == '2') {
          conStateRate = '30'
        } else if (that.gapTime == '3') {
          conStateRate = '90'
        }
        //判断一级 设备管理 名称是否能找到
      let DeviceName = that.$store.state.selectList.find(item=>item.name=="DeviceManage")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(DeviceName?.name){
        DeviceName.children.forEach((element) => {
          if (element.name.indexOf('Device') != -1) {
            console.log(conStateRate,"conValue--------------------")
            that.$router.push({
              name: 'Device',
              query: {
                key: '6',
                keyPath: ['6'],
                // falgKeypath: true,
                // fl: true,
                jumpPageName:'SourceHome',//用于跳转后页面判断
                conStatus: conValue,
                onlineValue: conStateRate
              }
            })
          } else {
            return false
          }
        })
      }
      })
      option && myChart.setOption(option)
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.devActive,
.foul {
  width: 100%;
  height: 88%;
  .el-select {
    .el-input__inner {
      padding-left: 0rem;
    }
  }
}
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 下拉框  修改 大小、字体字号
  ::v-deep .el-input--mini .el-input__inner {
    height: 37px;
    line-height: 28px;
    font-size: 18px;
  }
  #scount {
    width: 99% !important;
    height: 100% !important;
    div {
      width: 100% !important;
      height: 100% !important;
      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 24px; //卡片title
      .timeTypeFlog {
        .timeType {
          //时间范围下拉框
          display: inline-block;
          // 宽度调整
          width: 100px;
          // 时间范围字体大小
          font-size: 20px;
        }
        .el-select {
          width: 123px;
          margin-left: 5px;
          padding: 0rem;
          font-size: 4px;
        }
      }
      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
    .noData {
      font-family: 'Microsoft YaHei';
      font-weight: bold;
      font-size: 23px;
      color: #9d9d9d;
      text-align: center;
      line-height: 230px;
    }
  }
}
@media screen and (min-width: 1920px) and (max-width: 2040px) {
  #scount {
    width: 99% !important;
    height: 100% !important;
    div {
      width: 100% !important;
      height: 100% !important;
      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 20.8px; //卡片title
      .timeTypeFlog {
        .timeType {
          //时间范围下拉框
          display: inline-block;
          width: 52px;
          font-size: 12.8px;
        }
        .el-select {
          width: 95px;
          margin-left: 5px;
          padding: 0rem;
          font-size: 4px;
        }
      }
      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
    .noData {
      font-family: 'Microsoft YaHei';
      font-weight: bold;
      font-size: 23px;
      color: #9d9d9d;
      text-align: center;
      line-height: 230px;
    }
  }
}
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  #scount {
    width: 99% !important;
    height: 100% !important;
    div {
      width: 100% !important;
      height: 100% !important;
      canvas {
        width: 92% !important;
        height: 100% !important;
      }
    }
  }
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 20.8px; //卡片title
      .timeTypeFlog {
        .timeType {
          //时间范围下拉框
          display: inline-block;
          width: 52px;
          font-size: 12.8px;
        }
        .el-select {
          width: 95px;
          margin-left: 5px;
          padding: 0rem;
          font-size: 4px;
        }
      }
      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
  }
}
@media screen and (max-width: 1680px) {
  #scount {
    width: 99% !important;
    height: 100% !important;
    div {
      width: 100% !important;
      height: 100% !important;
      canvas {
        width: 92% !important;
        height: 100% !important;
      }
    }
  }
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 20.8px; //卡片title
      .timeTypeFlog {
        .timeType {
          //时间范围下拉框
          display: inline-block;
          width: 52px;
          font-size: 12.8px;
        }
        .el-select {
          width: 95px;
          margin-left: 5px;
          padding: 0rem;
          font-size: 4px;
        }
      }
      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
  }
}
</style>
