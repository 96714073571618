<template>
  <div class="grid-content">
    <!-- 系统 -->
    <div class="title" style="color: #8a909e">
      <div class="titleFlog"></div>
      {{ $t("home.middle.SystemUsage") }}
    </div>
    <div class="system">
      <ul class="cpu">
        <li>
          <span class="usage">{{ $t("home.middle.cpuUseRate") }}:</span>
          <!-- 使用率 -->
          <span class="percentage cpuClass" style="color: #3e6ef1">{{ systemUsage.useCpu || "0" }}</span>
        </li>
        <li style="display: flex; flex-direction: column">
          <!-- 已使用 -->
          <span class="percentage">{{ systemUsage.useCpu || "0" }}</span><span class="used">{{ $t("home.middle.used") }}</span>
        </li>
        <li style="display: flex; flex-direction: column">
          <!-- 总量 -->
          <span class="percentage">{{ systemUsage.cpuSum || "0" }}</span><span class="used">{{ $t("home.middle.gross") }}</span>
        </li>
      </ul>
      <ul class="internal">
        <li style="list-style: none !important">
          <span class="usage">{{ $t("home.middle.MemoryUsage") }}:</span>
          <!-- 内存shiyonglv -->
          <span class="percentage" style="color: #fab215; ">{{
            systemUsage.memorySize || "0"
          }}</span>
        </li>
        <li style="list-style: none !important">
          <span class="percentage" style="padding-left: 10px">{{
            systemUsage.usedMemorySize || "0"
          }}</span><span class="used">{{ $t("home.middle.used") }}</span>
        </li>
        <li style="list-style: none !important">
          <!-- 内存总量 -->
          <span class="percentage">{{ TotalMemory || "0" }}</span><span class="used">{{ $t("home.middle.gross") }}</span>
        </li>
      </ul>
      <ul class="hardDisk">
        <li style="list-style: none !important">
          <span class="usage">{{ $t("home.middle.DiskUsage") }}:</span>
          <span style="color: #1faa6f">{{
            systemUsage.hardDiskSize || "0"
          }}</span>
        </li>
        <li style="list-style: none !important">
          <span>{{ systemUsage.usedHardDiskSize || "0" }}</span><span class="used">{{ $t("home.middle.used") }}</span>
        </li>
        <li style="list-style: none !important">
          <span>{{ systemUsage.usableHardDiskSize || "0" }}</span><span class="used">{{ $t("home.middle.gross") }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      systemUsage: {}
    }
  },
  computed: {
    TotalMemory() {
      if (
        this.systemUsage.usedMemorySize &&
        this.systemUsage.usableMemorySize
      ) {
        let str1 = this.systemUsage.usedMemorySize.legend - 1
        let str2 = this.systemUsage.usableMemorySize.length - 1
        let result1 = this.systemUsage.usedMemorySize?.substring(0, str1)
        let result2 = this.systemUsage.usableMemorySize?.substring(0, str2)
        return result1 + result2 + 'G'
      }
    }
  },
  mounted() {
    this.getSystem()
  },
  methods: {
    // 获取系统使用情况
    async getSystem() {
      var params
      params = {}
      const res = await this.$axios.post(
        '/httpServe/getSystemInfo',
        params,
        true
      )
      // // console.log(res.data, "系统数据====================");
      this.systemUsage = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 标题距离上面间隙占比
  .grid-content .title {
    font-size: 24px;
    margin-top: 1%;
  }
  .system {
    width: 100%;
    height: 80%;
    // 整体间距占比调整
    margin-top: 6%;
    ul {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 1.25rem;
      li {
        width: 149px;
        // justify-content: space-between;
        text-align: center;
        // 带有百分比 字体大小
        font-size: 17px;
        color: black;
        .usage {
          //使用率 字体大小
          font-size: 15px;
        }
        // // 带有百分比 字体大小
        .percentage {
          font-size: 15px;
        }
        em {
          font-size: 15px;
          font-style: normal;
        }
        .used {
          //已使用  字体大小
          color: #b2b2b2;
          font-size: 15px;
          display: block;
        }
      }
      li::marker {
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 2040px) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title
    .timeTypeFlog {
      .timeType {
        //时间范围下拉框
        display: inline-block;
        width: 52px;
        font-size: 12.8px;
      }
      .el-select {
        width: 89px;
        margin-left: 5px;
        padding: 0rem;
        font-size: 4px;
      }
    }
    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
  .system {
    width: 100%;
    height: 80%;
    ul {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 1.25rem;
      li {
        width: 148px;
        // justify-content: space-between;
        text-align: center;
        font-size: 15px;
        color: black;
        .usage {
          //使用率
          font-size: 15px;
        }
        em {
          font-size: 15px;
          font-style: normal;
        }
        .used {
          //已使用
          color: #b2b2b2;
          font-size: 15px;
          display: block;
        }
      }
      li::marker {
        color: #fff;
      }
    }
  }
}
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title
    .timeTypeFlog {
      .timeType {
        //时间范围下拉框
        display: inline-block;
        width: 52px;
        font-size: 12.8px;
      }
      .el-select {
        width: 89px;
        margin-left: 5px;
        padding: 0rem;
        font-size: 4px;
      }
    }
    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
  .system {
    width: 100%;
    height: 80%;
    ul {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 1.25rem;
      li {
        width: 149px;
        // justify-content: space-between;
        text-align: center;
        font-size: 0.8125rem;
        color: black;
        .usage {
          //使用率
          font-size: 0.8125rem;
        }
        em {
          font-size: 0.8125rem;
          font-style: normal;
        }
        .used {
          //已使用
          color: #b2b2b2;
          font-size: 0.8125rem;
          display: block;
        }
      }
      li::marker {
        color: #fff;
      }
    }
  }
}
@media screen and (max-width: 1679px) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title
    .timeTypeFlog {
      .timeType {
        //时间范围下拉框
        display: inline-block;
        width: 52px;
        font-size: 12.8px;
      }
      .el-select {
        width: 89px;
        margin-left: 5px;
        padding: 0rem;
        font-size: 4px;
      }
    }
    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
  .system {
    width: 100%;
    height: 80%;
    ul {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 1.25rem;
      li {
        width: 148px;
        // justify-content: space-between;
        text-align: center;
        font-size: 0.75rem;
        color: black;
        .usage {
          //使用率
          font-size: 0.75rem;
        }
        em {
          font-size: 0.75rem;
          font-style: normal;
        }
        .used {
          //已使用
          color: #b2b2b2;
          font-size: 0.75rem;
          display: block;
        }
      }
      li::marker {
        color: #fff;
      }
    }
  }
}
</style>
