<template>
  <div class="grid-content">
    <div class="title titles" style="color: #8a909e">
      <div>
        <div class="titleFlog"></div>
        {{ $t("home.third.ViolationTrend") }}
      </div>
      <div class="timeTypeFlog">
        <div class="timeType">{{$t("public.TimeFrame")}}</div>
        <el-select v-model="behaviorTimeType" size="mini" :placeholder="$t('home.middle.choose')" @change="onChangeBehavior">
          <el-option v-for="item in behaviorOptions" :key="item.value" :label="$t(`home.middle.${item.label}`)" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="foul">
      <div id="foulPlay"></div>
      <!--v-if="!showFlag1" <div v-else-if="showFlag1" class="noData">
        暂无数据
      </div> -->
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'
export default {
  data() {
    return {
      showFlag1: false,
      //违规行为趋势下拉框
      behaviorOptions: [
        {
          value: '0',
          label: '当天',
          scope: 1
        },
        {
          value: '1',
          label: '近7天',
          scope: 7
        },
        {
          value: '2',
          label: '近30天',
          scope: 30
        },
        {
          value: '3',
          label: '近90天',
          scope: 90
        }
      ],
      //双向绑定value值
      behaviorTimeType: '1',
      //违规行为趋势
      behaviorTrend: {},

      //违规行为分布
      behaviorDistributSum: [],
      startTime: '',
      dateGrop: [], //时间组
      beScopeTimes: 7, //违规行为趋势选择时间范围
      dayScope: '', //折线图数据起始日期
      saveAllList: [] //批量vuex中保存的菜单数组
    }
  },
  watch: {
    "$i18n.locale"(newVal,old) {
      console.log(newVal,old,'val,old');
      // this.CurLangMode=newVal
      //监听语言的变化，每次变动都要重新渲染图表
      this.getViolaTrend();
    },
  },
  mounted() {
    var enttime = moment().format('YYYY-MM-DD')
    var statiem = moment().subtract(6, 'days').format('YYYY-MM-DD')
    this.getdiffdate(statiem, enttime)
    setTimeout(() => {
      this.getViolaTrend()
      this.saveAllList = [...this.$store.state.selectList]
      // console.log(this.saveAllList, '--this.saveAllList路由')
    }, 800)
  },
  methods: {
    //获取两日期之间日期列表函数
    getdiffdate(stime, etime) {
      //初始化日期列表，数组
      var diffdate = new Array()
      var i = 0
      //开始日期小于等于结束日期,并循环
      while (stime <= etime) {
        diffdate[i] = stime

        //获取开始日期时间戳
        var stime_ts = new Date(stime).getTime()

        //增加一天时间戳后的日期
        var next_date = stime_ts + 24 * 60 * 60 * 1000

        //拼接年月日，这里的月份会返回（0-11），所以要+1
        var next_dates_y = new Date(next_date).getFullYear() + '-'
        var next_dates_m =
          new Date(next_date).getMonth() + 1 < 10
            ? '0' + (new Date(next_date).getMonth() + 1) + '-'
            : new Date(next_date).getMonth() + 1 + '-'
        var next_dates_d =
          new Date(next_date).getDate() < 10
            ? '0' + new Date(next_date).getDate()
            : new Date(next_date).getDate()

        stime = next_dates_y + next_dates_m + next_dates_d

        //增加数组key
        i++
      }
      this.dateGrop = diffdate
    },
    //获取违规行为趋势
    onChangeBehavior(v) {
      this.beScopeTimes = this.behaviorOptions[v].scope
      this.gapTime = v
      // // console.log(this.gapTime, "获取时间范围=================");
      var day = this.behaviorOptions[v].scope - 1
      var enttime = moment().format('YYYY-MM-DD')
      var statiem = moment().subtract(day, 'days').format('YYYY-MM-DD')
      this.getdiffdate(statiem, enttime)

      this.getViolaTrend()
    },
    async getViolaTrend() {
      var params
      params = {
        groupID: '1',
        timeType: this.behaviorTimeType
      }
      const res = await this.$axios.post(
        '/httpServe/getViolaTrend',
        params,
        true
      )
      console.log(res.data, '获取违规行为趋势========')
      // if (res.data.violationCount == null && res.data.times == null) {
      //   this.showFlag1 = true
      // } else {
      //   this.showFlag1 = false
      // }
      if (this.beScopeTimes == 1) {
        this.beScopeTimes = 24
        this.dateGrop = [
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24'
        ]
      }
      let emptyArr = Array(this.beScopeTimes).fill(0)
      let newDateGrop = this.dateGrop //根据当前时间退出前n天的日期
      let arrTimes = res.data.times?res.data.times:this.dateGrop
      let violaCountArr = res.data.violationCount?res.data.violationCount:emptyArr
      for (var i = 0; i < newDateGrop.length; i++) {
        for (var j = 0; j < arrTimes?.length; j++) {
          if (newDateGrop[i] == arrTimes[j]) {
            //确定i的大小，即是其角标
            // emptyArr[i] = 4;
            emptyArr[i] = violaCountArr[j]
          }
        }
      }
      // // console.log(emptyArr, "emptyArr1emptyArr1emptyArr1");
      res.data.violationCount = emptyArr
      res.data.times = this.dateGrop
      // if (this.gapTime === '0') {
      //   this.dayScope = moment().format('YYYY-MM-DD')
      // } else {
      //   this.dayScope = this.dateGrop[0]
      // }
      // // console.log(this.dayScope);
      this.foulPlay(res.data)
    },
    // 第三个违规折线阴影
    foulPlay(behaviorTrend) {
      // // console.log(behaviorTrend, "图表中的数据");
      let max = Math.max.apply(null, behaviorTrend.violationCount)
      var chartDom = document.getElementById('foulPlay')
      var myChart = echarts.init(chartDom)
      var that = this
      var interval = that.beScopeTimes == 7 ? 1 : that.beScopeTimes / 6
      var option
      option = {
        color: '#4272FF',
        tooltip: {
          confine: false,
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              show: false
            },
            backgroundColor: 'rgba(67,112,242)'
          }
        },

        grid: {
          left: '2%',
          right: '5%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              // interval: 4 //加入axisLabel字段,interval后面加你想要间隔的个数
              interval: (index, value) => {
                if (index == 0) {
                  return true
                }

                return (index + 1) % interval == 0
              }
            },

            data: behaviorTrend.times
          }
        ],

        yAxis: [
          {
            axisLine: { show: true },
            type: 'value',
            max: max <= 10 ? 10 : max,
            min: 0
            // 数据最大值最小值
            // min: function (value) {
            //   return value.min || 0
            // },
            // max: function (value) {
            //   return value.max || 100
            // }
          }
        ],
        series: [
          {
            name:this.$t("home.third.violationsNum"), //违规数量
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 0
            },

            areaStyle: {
              opacity: 0.8,
              color: '#DEDEE0'
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: '#4272FF'
                }
              }
            },
            data: behaviorTrend.violationCount
          }
        ]
      }
      myChart.on('click', (param) => {
        //判断一级 用户管理 名称是否能找到
        let ViolationName = that.$store.state.selectList.find(item=>item.name=="SecurityMonitor")
          // let DeviceName = phoneName.find(item=>item.name=="Device")
          if(ViolationName?.name){
            ViolationName.children.forEach((element,index) => {
              if (element.name.indexOf('ViolationMonitor') != -1) {
                that.$router.push({
                  name: 'ViolationMonitor',
                  query: {
                        key: index+'',
                        // keyPath: ["2"],
                falgKeypath: true,
                fl: true,
                flag: 2,
                startTime: param.name
              }
                })
              } else {
                return false
              }
            })
          }





        // that.saveAllList.forEach((element) => {
        //   if (element.name.indexOf('SecurityMonitor') != -1) {
        //     that.$router.push({
        //       name: 'ViolationMonitor',
        //       query: {
        //         key: '6',
        //         keyPath: ['6'],
        //         falgKeypath: true,
        //         fl: true,
        //         flag: 2,
        //         startTime: param.name
        //       }
        //     })
        //   } else {
        //     return false
        //   }
        // })
      })
      option && myChart.setOption(option)
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.devActive,
.foul {
  width: 100%;
  height: 88%;
  .el-select {
    .el-input__inner {
      padding-left: 0rem;
    }
  }
  // #foulPlay {
  //   width: 99% !important;
  //   height: 100% !important;
  //   div {
  //     width: 100% !important;
  //     height: 100% !important;
  //     canvas {
  //       width: 97% !important;
  //       height: 100% !important;
  //     }
  //   }
  // }
}
.grid-content {
  .titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  height: 100%;
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title
    .timeTypeFlog {
      .timeType {
        //时间范围下拉框
        display: inline-block;
        width: 52px;
        font-size: 12.8px;
      }
      .el-select {
        width: 95px;
        margin-left: 5px;
        padding: 0rem;
        font-size: 4px;
      }
    }
    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
  .noData {
    font-family: 'Microsoft YaHei';
    font-weight: bold;
    font-size: 23px;
    color: #9d9d9d;
    text-align: center;
    line-height: 230px;
  }
}
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  #foulPlay {
    width: 99% !important;
    height: 100% !important;
    div {
      width: 100% !important;
      height: 100% !important;
      canvas {
        width: 97% !important;
        height: 100% !important;
      }
    }
  }
  // 下拉框  修改 大小、字体字号
  ::v-deep .el-input--mini .el-input__inner {
    height: 37px;
    line-height: 28px;
    font-size: 18px;
  }
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 24px; //卡片title 字体大小

      .timeTypeFlog {
        .timeType {
          //时间范围下拉框
          display: inline-block;
          width: 100px;
          font-size: 20px;
        }
        .el-select {
          width: 123px;
          margin-left: 5px;
          padding: 0rem;
          font-size: 4px;
        }
      }
      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
  }
}
@media screen and (min-width: 1920px) and (max-width: 2040px) {
  #foulPlay {
    width: 99% !important;
    height: 100% !important;
    div {
      width: 100% !important;
      height: 100% !important;
      canvas {
        width: 97% !important;
        height: 100% !important;
      }
    }
  }
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 20.8px; //卡片title
      .timeTypeFlog {
        .timeType {
          //时间范围下拉框
          display: inline-block;
          width: 52px;
          font-size: 12.8px;
        }
        .el-select {
          width: 95px;
          margin-left: 5px;
          padding: 0rem;
          font-size: 4px;
        }
      }
      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
  }
}
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  #foulPlay {
    width: 99% !important;
    height: 100% !important;
    div {
      width: 100% !important;
      height: 100% !important;
      canvas {
        width: 92% !important;
        height: 100% !important;
      }
    }
  }
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 20.8px; //卡片title
      .timeTypeFlog {
        .timeType {
          //时间范围下拉框
          display: inline-block;
          width: 52px;
          font-size: 12.8px;
        }
        .el-select {
          width: 95px;
          margin-left: 5px;
          padding: 0rem;
          font-size: 4px;
        }
      }
      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
  }
}
@media screen and (max-width: 1679px) {
  #foulPlay {
    width: 99% !important;
    height: 100% !important;
    div {
      width: 100% !important;
      height: 100% !important;
      canvas {
        width: 92% !important;
        height: 100% !important;
      }
    }
  }
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 20.8px; //卡片title
      .timeTypeFlog {
        .timeType {
          //时间范围下拉框
          display: inline-block;
          width: 52px;
          font-size: 12.8px;
        }
        .el-select {
          width: 95px;
          margin-left: 5px;
          padding: 0rem;
          font-size: 4px;
        }
      }
      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
  }
}
</style>

