var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-content"},[_c('div',{staticClass:"equipmentText"},[_c('div',{staticClass:"title",on:{"click":function($event){$event.stopPropagation();return _vm.goUserList.apply(null, arguments)}}},[_c('span',{staticClass:"titles"},[_vm._v(_vm._s(_vm.$t("home.top.userSum")))]),_c('span',{staticClass:"unit",staticStyle:{"color":"black","cursor":"pointer"}},[_c('big',{staticClass:"zSize",staticStyle:{"margin-right":"10px","cursor":"pointer"}},[_vm._v(_vm._s(_vm.userChild.userSum || "0"))]),_vm._v(" "+_vm._s(_vm.$t("home.top.few")))],1)]),_c('div',{staticClass:"strip"},[_c('div',{staticClass:"offline",on:{"click":function($event){$event.stopPropagation();return _vm.goOutService.apply(null, arguments)}}},[_c('el-progress',{attrs:{"percentage":100,"show-text":false,"color":"#D7E7F7"}})],1),_c('div',{staticClass:"online",style:({
                width:
                  ((Number(this.userChild.lockUserCount) +
                    Number(this.userChild.averageUserCount)) /
                    _vm.userChild.userSum) *
                    100 +
                  '%'
              }),on:{"click":function($event){$event.stopPropagation();return _vm.goLock.apply(null, arguments)}}},[_c('el-progress',{attrs:{"percentage":100,"show-text":false,"color":"#FFA600"}})],1),_c('div',{staticClass:"outService",style:({
                width:
                  (_vm.userChild.averageUserCount / _vm.userChild.userSum) *
                    100 +
                  '%'
              }),on:{"click":_vm.goNormal}},[_c('el-progress',{attrs:{"percentage":100,"show-text":false,"color":"#3E6EF1"}})],1)]),_c('div',{staticClass:"percenText"},[_c('span',{staticClass:"blue",on:{"click":_vm.goNormal}},[_vm._v(_vm._s(_vm.$t("home.top.average"))+" "+_vm._s(_vm.userChild.averageUserCount || "0"))]),_c('span',{staticClass:"orange",on:{"click":_vm.goLock}},[_vm._v(_vm._s(_vm.$t("home.top.lock"))+" "+_vm._s(_vm.userChild.lockUserCount || "0"))]),_c('span',{staticClass:"gary",on:{"click":_vm.goOutService}},[_vm._v(_vm._s(_vm.$t("home.top.dead"))+" "+_vm._s(_vm.userChild.deactivateUserCount || "0"))])])]),_c('div',{staticClass:"equipmentPic",staticStyle:{"cursor":"pointer"},on:{"click":_vm.goUserList}},[_c('img',{staticClass:"image",attrs:{"src":require("../../../../images/home/users.png")},on:{"click":_vm.goUserList}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }