<template>
  <div class="grid-content">
    <!-- 用户 -->
    <div class="equipmentText">
      <div class="title" @click.stop="goUserList">
        <span class="titles">{{ $t("home.top.userSum") }}</span>
        <span class="unit" style="color: black;cursor: pointer">
          <big class="zSize" style=" margin-right: 10px;cursor: pointer">{{
                  userChild.userSum || "0"
                }}</big>
          {{ $t("home.top.few") }}</span>
      </div>
      <div class="strip">
        <div class="offline" @click.stop="goOutService">
          <el-progress :percentage="100" :show-text="false" color="#D7E7F7"></el-progress>
        </div>

        <div :style="{
                  width:
                    ((Number(this.userChild.lockUserCount) +
                      Number(this.userChild.averageUserCount)) /
                      userChild.userSum) *
                      100 +
                    '%'
                }" class="online" @click.stop="goLock">
          <el-progress :percentage="100" :show-text="false" color="#FFA600"></el-progress>
        </div>

        <div :style="{
                  width:
                    (userChild.averageUserCount / userChild.userSum) *
                      100 +
                    '%'
                }" class="outService" @click="goNormal">
          <el-progress :percentage="100" :show-text="false" color="#3E6EF1"></el-progress>
        </div>
      </div>
      <div class="percenText">
        <span class="blue" @click="goNormal">{{ $t("home.top.average") }}
          {{ userChild.averageUserCount || "0" }}</span>
        <span class="orange" @click="goLock">{{ $t("home.top.lock") }}
          {{ userChild.lockUserCount || "0" }}</span>
        <span class="gary" @click="goOutService">{{ $t("home.top.dead") }}
          {{ userChild.deactivateUserCount || "0" }}</span>
      </div>
    </div>
    <div class="equipmentPic" @click="goUserList" style="cursor: pointer">
      <img class="image" src="../../../../images/home/users.png" @click="goUserList" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userChild: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      saveAllList: [] //批量vuex中保存的菜单数组
    }
  },
  mounted() {
    setTimeout(() => {
      this.saveAllList = [...this.$store.state.selectList]
      // console.log(this.saveAllList, '--this.saveAllList路由')
    }, 800)
  },
  methods: {
    //点击跳转到用户列表
    goUserList() {
       //判断一级 用户管理 名称是否能找到
       let userName = this.$store.state.selectList.find(item=>item.name=="UserManage")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(userName?.name){
        userName.children.forEach((element) => {
          if (element.name.indexOf('User') != -1) {
            this.$router.push({
              name: 'User',
              query: {
              userFlag: -1
            }
            })
          } else {
            return false
          }
        })
      }



      // this.saveAllList.forEach((element) => {
      //   if (element.name.indexOf('UserManage') != -1) {
      //     this.$router.push({
      //       name: 'User',
      //       query: {
      //         userFlag: -1
      //       }
      //     })
      //   } else {
      //     return false
      //   }
      // })
    },
    //点击正常进度条
    goNormal() {
     //判断一级 用户管理 名称是否能找到
     let userName = this.$store.state.selectList.find(item=>item.name=="UserManage")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(userName?.name){
        userName.children.forEach((element) => {
          if (element.name.indexOf('User') != -1) {
            this.$router.push({
              name: 'User',
              query: {
              userFlag: 1
            }
            })
          } else {
            return false
          }
        })
      }
    },
    //点击锁定进度条
    goLock() {
     //判断一级 用户管理 名称是否能找到
     let userName = this.$store.state.selectList.find(item=>item.name=="UserManage")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(userName?.name){
        userName.children.forEach((element) => {
          if (element.name.indexOf('User') != -1) {
            this.$router.push({
              name: 'User',
              query: {
              userFlag: 2
            }
            })
          } else {
            return false
          }
        })
      }
    },
    //点击停用进度条
    goOutService() {
     //判断一级 用户管理 名称是否能找到
     let userName = this.$store.state.selectList.find(item=>item.name=="UserManage")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(userName?.name){
        userName.children.forEach((element) => {
          if (element.name.indexOf('User') != -1) {
            this.$router.push({
              name: 'User',
              query: {
              userFlag:3
            }
            })
          } else {
            return false
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.equipmentText {
  .title {
    height: 25%;
  }
  .strip {
    height: 10%;
    margin: 1.375rem 0rem !important;
    width: 100%;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    position: relative;
    .online {
      cursor: pointer;
      height: 0.375rem;
      position: absolute;
      top: 0rem;
      left: 0rem;
    }
    .outService {
      position: absolute;
      top: 0rem;
      left: 0rem;
      z-index: inherit;
      cursor: pointer
    }
    .outService {
      position: absolute;
      top: 0rem;
      left: 0rem;
      z-index: inherit;
      cursor: pointer
    }
    .offline{
      cursor: pointer
    }
  }

  .green {
    color: #00cf00;
  }

  .blue {
    color: #3e81e5;
  }
  .orange {
    color: #ffa600;
  }
  .gary {
    color: #9b9b9b;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2040px) {
  // 用户总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 3%;
  }
  // 用户 数 字体大小
  .zSize {
    font-size: 22px;
  }
  // 默认单位18
  .unit {
    font-size: 18px;
  }
  .titles {
    font-size: 20px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  .percenText {
    height: 25%;
    span {
      font-size: 16px;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  // 用户总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 3%;
  }
  // 用户 数 字体大小
  .zSize {
    font-size: 22px;
  }
  // 默认单位18
  .unit {
    font-size: 18px;
  }
  .titles {
    font-size: 18px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  .percenText {
    height: 25%;
    span {
      font-size: 16px;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 用户总数  字体大小
  .equipmentText {
    width: 70%;
    margin-top: 5%;
    margin-left: 2%;
  }

  // 设备 数 字体大小
  .zSize {
    font-size: 30px;
  }
  // 设备总数  字体大小
  .titles {
    font-size: 26px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  // 默认单位22
  .unit {
    font-size: 18px;
  }
  // 图表 间隙 占比
  .equipmentPic {
    margin-right: 5%;
  }
  // 图片大小 占比
  .image {
    margin-top: 27%;
    width: 127%;
  }
  .percenText {
    height: 25%;
    span {
      // 正常 锁定  停用字体大小
      font-size: 20px;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 1680px) {
  // 用户总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 3%;
  }
  // 用户 数 字体大小
  .zSize {
    font-size: 22px;
  }
  // 默认单位18
  .unit {
    font-size: 18px;
  }
  .titles {
    font-size: 18px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  .percenText {
    height: 25%;
    span {
      font-size: 16px;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
</style>

