<template>
  <div class="grid-content">
    <div class="title" style="color: #8a909e">
      <div class="titleFlog"></div>
      {{ $t("home.third.DistributionViola") }}
    </div>
    <div class="viola" v-if="!showFlag2">
      <div id="violaFound" style="width: 100%; height: 100%"></div>
    </div>
    <div v-else-if="showFlag2" class="noData">
      {{ $t("home.third.noData") }}
    </div>
    <!-- 其他违规弹框     style="z-index: 20000 !important;"-->
    <Eldialog
      class="dialog"
      @handleClose="handleClose"
      :title="$t('home.third.otherData')"
      :flagbtn="flagbtn"
      :determineBtn="determineBtn"
      @determine="sureBtn"
      :dialogVisible="otherViolaShow"
      :width="'300px'"
      v-if="otherViolaShow"
    >
      <div class="box-corn otherViolaList">
        <el-radio-group
          style="margin: 30px"
          v-model="radioValue"
          @change="radioChange"
          v-if="this.surplusBehaviorDistribut.length > 0"
        >
          <el-radio
            style="margin: 5px auto"
            :label="i.type"
            v-for="(i, k) in this.surplusBehaviorDistribut"
            :key="k"
            >{{ i.name }} ({{ i.proportion + "%" }})</el-radio
          >
        </el-radio-group>
        <div v-else style="margin: 30px">{{ $t("home.third.noData") }}</div>
      </div>
      <div v-if="flagbtn" slot="footer" class="diailog-footer">
        <el-button
          size="small"
          type="primary"
          class="btn-determine"
          @click="sureBtn"
          >{{ $t("public.Verify") }}</el-button
        >
      </div>
    </Eldialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import locale from "../../../../langs/locale";
import Eldialog from "@/components/elDialog"; // 详情
import { log } from 'console';
export default {
  components: {
    Eldialog,
  },
  data() {
    return {
      startTime: "",
      showFlag2: false, //违规行为图表是否显示
      radioValue: "", //其他违规弹框单选框默认选中
      surplusBehaviorDistribut: [], // 其他违规行为top5以外
      flagbtn: true, //违规弹框是否显示确定按钮
      otherViolaShow: false, //其他违规弹框显示
      determineBtn: true,
      showFlag2: false, //违规行为图表是否显示
      saveAllList: [], //批量vuex中保存的菜单数组
      radioValue: "", //其他违规弹框单选框默认选中
      determineBtn: true,
      flagbtn: true, //违规弹框是否显示确定按钮
      otherViolaShow: false, //其他违规弹框显示
      surplusBehaviorDistribut: [], // 其他违规行为top5以外
      visibaelFlag: false, //提示消息默认--关闭
      piechartArray: [], //违规数据
      CurLangMode:'zh',//当前语言模式
    };
  },
  watch: {
    "$i18n.locale"(newVal,old) {
      console.log(newVal,old,'val,old');
      this.CurLangMode=newVal
      //监听语言的变化，每次变动都要重新渲染图表
      this.getViolaDis();
    },
  },
  mounted() {
    var enttime = moment().format("YYYY-MM-DD");
    var statiem = moment().subtract(6, "days").format("YYYY-MM-DD");
    this.getdiffdate(statiem, enttime);

    setTimeout(() => {
      this.getViolaDis();
      this.saveAllList = [...this.$store.state.selectList];
    }, 800);
  },
  methods: {
    //获取两日期之间日期列表函数
    getdiffdate(stime, etime) {
      //初始化日期列表，数组
      var diffdate = new Array();
      var i = 0;
      //开始日期小于等于结束日期,并循环
      while (stime <= etime) {
        diffdate[i] = stime;

        //获取开始日期时间戳
        var stime_ts = new Date(stime).getTime();

        //增加一天时间戳后的日期
        var next_date = stime_ts + 24 * 60 * 60 * 1000;

        //拼接年月日，这里的月份会返回（0-11），所以要+1
        var next_dates_y = new Date(next_date).getFullYear() + "-";
        var next_dates_m =
          new Date(next_date).getMonth() + 1 < 10
            ? "0" + (new Date(next_date).getMonth() + 1) + "-"
            : new Date(next_date).getMonth() + 1 + "-";
        var next_dates_d =
          new Date(next_date).getDate() < 10
            ? "0" + new Date(next_date).getDate()
            : new Date(next_date).getDate();

        stime = next_dates_y + next_dates_m + next_dates_d;

        //增加数组key
        i++;
      }
      this.dateGrop = diffdate;
    },
    //获取违规行为分布
    async getViolaDis() {
      var params;
      params = {
        groupID: "1",
      };
      const res = await this.$axios.post(
        "/httpServe/getViolaDis",
        params,
        true
      );
      // console.log(res, '获取违规行为分布shuju ')
      this.startTime = res.data.startTime;
      this.piechartArray = res.data.piechart;
      this.violaFound(res.data.piechart, this.startTime);
      if (res.data.piechart.length == 0) {
        this.showFlag2 = true;
      } else {
        this.showFlag2 = false;
        this.violaFound(this.piechartArray, this.startTime);
      }
    },

    // 第四个饼图
    violaFound(behaviorDistribut, startTime) {
      var dayjs = require("dayjs");
      var newTime = dayjs(startTime).format("YYYY-MM-DD");
      var total = 0;
      if (behaviorDistribut.length > 0) {
        behaviorDistribut.forEach((item, k) => {
          // console.log(item.violationType,"item--------");
          item.violationTitle = locale.locale(
            "violation.reason_" + item.violationType,this.CurLangMode
          );
          // console.log(item.violationTitle,"item.violationTitle",this.CurLangMode);
          item.violationCount = Number(item.violationCount || "0");
          total += item.violationCount;
        });
      }
      var newBehaviorDistribut = [];
      var newBehaviorDistributType = [];
      var surplusBehaviorDistribut1 = [];
      for (var i = 0; i < behaviorDistribut?.length; i++) {
        // console.log(behaviorDistribut[i].violationTitle,"behaviorDistribut[i].violationTitle")
        if (i >= 5) {
          surplusBehaviorDistribut1.push({
            name: behaviorDistribut[i].violationTitle,
            value: behaviorDistribut[i].violationCount,
            type: behaviorDistribut[i].violationType,
            proportion: (
              (behaviorDistribut[i].violationCount / total) *
              100
            ).toFixed(2),
          });
          this.surplusBehaviorDistribut = surplusBehaviorDistribut1;
        }
        if (i < 5) {
          newBehaviorDistribut.push({
            name: behaviorDistribut[i].violationTitle,
            value: behaviorDistribut[i].violationCount,
            type: behaviorDistribut[i].violationType,
          });
          newBehaviorDistributType.push(behaviorDistribut[i].violationType);
        } else {
          if (i === 5) {
            newBehaviorDistribut.push({
              name: locale.locale("violation.reason_99",this.CurLangMode),
              value: 0,
            });
            newBehaviorDistributType.push(locale.locale("violation.reason_99",this.CurLangMode));
          }
          //其他违规的value等于剩余所有的总和
          newBehaviorDistribut[5].value += Number(
            behaviorDistribut[i].violationCount || "0"
          );
        }
        // locale.converLanguage(newBehaviorDistribut)
        // console.log(newBehaviorDistribut,"newBehaviorDistribut");
      }
      var chartDom = document.getElementById("violaFound");
      var myChart = echarts.init(chartDom);
      var option;
      var that = this;
      option = {
        color: [
          "#3E6EF1",
          "#43B1FE",
          "#A6CCF6",
          "#58DA55",
          "#FAC858",
          "#EE6666",
        ],
        tooltip: {
          trigger: "item",
          show: false,
        },
        legend: {
          // 图例列表的布局朝向
          orient: "vertical",
          right: 5,
          top: 0,
          // bottom: 10,
          icon: "circle",
          itemWidth: 5,
          itemHeight: 5,
          width: "30%", //图例组件宽度
          tooltip: {
            show: true,
          },
          textStyle: {
            fontSize: 11,
          },
          // 重写legend显示样式
          formatter: function (name) {
            // 获取legend显示内容
            let data = option.series[0].data;
            let total = 0;
            let tarValue = 0;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            // let text = name + ' ' + tarValue + '次'
            // if (text.length <= 8) {
            //   return text
            // } else if (text.length > 8 && text.length <= 16) {
            //   return (text = `${text.slice(0, 8)}\n${text.slice(8)}`)
            // } else if (text.length > 16 && text.length <= 24) {
            //   return (text = `${text.slice(0, 8)}\n${text.slice(
            //     8,
            //     16
            //   )}\n${text.slice(16)}`)
            // } else if (text.length > 24 && text.length <= 30) {
            //   return (text = `${text.slice(0, 8)}\n${text.slice(
            //     8,
            //     16
            //   )}\n${text.slice(16, 24)}\n${text.slice(24)}`)
            // } else if (text.length > 30) {
            //   return (text = `${text.slice(0, 8)}\n${text.slice(
            //     8,
            //     16
            //   )}\n${text.slice(16, 24)}\n${text.slice(24, 30)}\n${text.slice(
            //     30
            //   )}`)
            // }

            // let p = ((tarValue / total) * 100).toFixed(2);
            return name;
          },
          data: newBehaviorDistribut.name,
        },
        series: [
          {
            type: "pie",
            // 图表旋转
            startAngle: 180,
            //饼图大小设置
            radius: ["30%", "50%"], // 设置环形饼状图， 第一个百分数设置内圈大小，第二个百分数设置外圈大小
            center: ["49%", "55%"], // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
            //标签放在图表中间
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            areaStyle: {
              normal: {
                // color: "#EAEAF0", //改变区域颜色
                position: "center",
                formatter: "{c}", // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容
              },
            },
            //视觉引导线
            labelLine: {
              show: true,
            },
            //饼图图形上的文本标签
            label: {
              show: true,
              formatter: "{b} \n{c}" + this.$t("home.top.throughs") + "\n{d}%",
            },
            data: newBehaviorDistribut,
            graphic: {
              type: "text", // 类型：文本
              left: "center",
              top: "middle",
              silent: true, // 不响应事件
              invisible: newBehaviorDistribut.legend > 0, // 有数据就隐藏
              style: {
                fill: "#9d9d9d",
                fontWeight: "bold",
                text: "暂无数据",
                fontFamily: "Microsoft YaHei",
                fontSize: "23",
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      console.log(that.CurLangMode,'that.CurLangMode');
      function eConsole(param) {
        //系统
        // if (param.dataIndex == 0) {
        //   that.saveAllList.forEach((element) => {
        //     if (element.name.indexOf('SecurityMonitor') != -1) {
        //       that.$router.push({
        //         name: 'ViolationMonitor',
        //         query: {
        //           key: '2',
        //           flag: false,
        //           reason: param.data.type,
        //           startTime: newTime
        //         }
        //       })
        //     }
        //   })
        // } else if (param.dataIndex == 1) {
        //   that.saveAllList.forEach((element) => {
        //     if (element.name.indexOf('SecurityMonitor') != -1) {
        //       that.$router.push({
        //         name: 'ViolationMonitor',
        //         query: {
        //           key: '2',
        //           flag: false,
        //           reason: param.data.type,
        //           startTime: newTime
        //         }
        //       })
        //     }
        //   })
        // } else if (param.dataIndex == 2) {
        //   that.saveAllList.forEach((element) => {
        //     if (element.name.indexOf('SecurityMonitor') != -1) {
        //       that.$router.push({
        //         name: 'ViolationMonitor',
        //         query: {
        //           key: '2',
        //           flag: false,
        //           reason: param.data.type,
        //           startTime: newTime
        //         }
        //       })
        //     }
        //   })
        // } else if (param.dataIndex == 3) {
        //   that.saveAllList.forEach((element) => {
        //     if (element.name.indexOf('SecurityMonitor') != -1) {
        //       that.$router.push({
        //         name: 'ViolationMonitor',
        //         query: {
        //           key: '2',
        //           flag: false,
        //           reason: param.data.type,
        //           startTime: newTime
        //         }
        //       })
        //     }
        //   })
        // } else if (param.dataIndex == 4) {
        //   that.saveAllList.forEach((element) => {
        //     if (element.name.indexOf('SecurityMonitor') != -1) {
        //       that.$router.push({
        //         name: 'ViolationMonitor',
        //         query: {
        //           key: '2',
        //           flag: false,
        //           reason: param.data.type,
        //           startTime: newTime
        //         }
        //       })
        //     }
        //   })
        // } else

        if (param.name == locale.locale("violation.reason_99",that.CurLangMode)) {
          that.radioValue = that.surplusBehaviorDistribut[0]?.type;
          that.otherViolaShow = true;
        } else if (param.name != locale.locale("violation.reason_99",that.CurLangMode)) {
          //判断一级 用户管理 名称是否能找到
          let ViolationName = that.$store.state.selectList.find(
            (item) => item.name == "SecurityMonitor"
          );
          // let DeviceName = phoneName.find(item=>item.name=="Device")
          if (ViolationName?.name) {
            ViolationName.children.forEach((element, index) => {
              if (element.name.indexOf("ViolationMonitor") != -1) {
                that.$router.push({
                  name: "ViolationMonitor",
                  query: {
                    key: index + "",
                    // keyPath: ["2"],
                    flag: false,
                    reason: param.data.type,
                    startTime: newTime,
                  },
                });
              } else {
                return false;
              }
            });
          }

          // that.saveAllList.forEach((element) => {
          //   if (element.name.indexOf('SecurityMonitor') != -1) {
          //     that.$router.push({
          //       name: 'ViolationMonitor',
          //       query: {
          //         key: '2',
          //         flag: false,
          //         reason: param.data.type,
          //         startTime: newTime
          //       }
          //     })
          //   }
          // })
        }
      }
      myChart.on("click", eConsole);
    },
    //切换其他违规信息
    radioChange(val) {
      this.checkRadio = val;
    },
    //弹框确认按钮
    sureBtn() {
      this.otherViolaShow = false;
      var dayjs = require("dayjs");
      var newTime = dayjs(this.startTime).format("YYYY-MM-DD");
      if (this.surplusBehaviorDistribut.length > 0) {
        //判断一级 用户管理 名称是否能找到
        let ViolationName = this.$store.state.selectList.find(
          (item) => item.name == "SecurityMonitor"
        );
        if (ViolationName?.name) {
          ViolationName.children.forEach((element) => {
            if (element.name.indexOf("ViolationMonitor") != -1) {
              this.$router.push({
                name: "ViolationMonitor",
                query: {
                  key: "2",
                  flag: false,
                  reason: this.radioValue,
                  startTime: newTime,
                },
              });
            } else {
              return false;
            }
          });
        }
      } else {
        this.otherViolaShow = false;
      }
    },
    //点击关闭其他违规弹框
    handleClose() {
      this.otherViolaShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.otherViola {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  border-radius: 0.3125rem;
  z-index: 9999999999999999;
  background: #fff;
  border: 0.0625rem solid #999999;
  .maskTitle {
    font-size: 0.8625rem;
    padding: 0.9375rem 0.625rem;
    font-weight: bold;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #1e89e5;
    color: #fff;
    border-radius: 0.3125rem;
  }
  .otherViolaList {
    width: 100%;
    .el-radio {
      display: block;
      margin: 0.625rem 5rem;
    }
  }
  .maskButton {
    display: flex;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    padding: 0.625rem 0.9375rem;
    font-weight: bold;
    border-top: 0.0625rem solid #999999;
  }
}
.grid-content {
  .titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  height: 100%;
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title
    .timeTypeFlog {
      .timeType {
        //时间范围下拉框
        display: inline-block;
        width: 52px;
        font-size: 12.8px;
      }
      .el-select {
        width: 95px;
        margin-left: 5px;
        padding: 0rem;
        font-size: 4px;
      }
    }
    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
  .viola {
    height: 80%;
    #violaFound {
      word-break: break-all;
      word-wrap: break-word;
    }
  }
  .noData {
    font-family: "Microsoft YaHei";
    font-weight: bold;
    font-size: 23px;
    color: #9d9d9d;
    text-align: center;
    line-height: 230px;
  }
}
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 24px; //卡片title
      // 卡片title 距离上层div 百分比
      margin-top: 1%;
      .timeTypeFlog {
        .timeType {
          //时间范围下拉框
          display: inline-block;
          width: 52px;
          font-size: 12.8px;
        }
        .el-select {
          width: 95px;
          margin-left: 5px;
          padding: 0rem;
          font-size: 4px;
        }
      }
      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
    .noData {
      font-family: "Microsoft YaHei";
      font-weight: bold;
      font-size: 23px;
      color: #9d9d9d;
      text-align: center;
      line-height: 230px;
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 2040px) {
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 20.8px; //卡片title

      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
  }
}
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 20.8px; //卡片title

      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
  }
}
@media screen and (max-width: 1679px) {
  .grid-content {
    .titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    height: 100%;
    .title {
      width: 100%;
      height: 12%;
      font-size: 20.8px; //卡片title

      .titleFlog {
        display: inline-block;
        width: 3px;
        height: 20px;
        background: #4669cd;
      }
    }
  }
}
</style>
