<template>
  <!-- 点击跳转到设备列表 -->
  <div class="grid-content" @click="goFacilityList">
    <!-- 设备 -->
    <div class="equipmentText">
      <div class="title" @click.stop="goFacilityList">
        <span class="titles">{{ $t("home.top.deviceSum") }}</span>
        <span class="unit" style="color: black;cursor: pointer"><big class="zSize" style=" margin-right: 10px;cursor: pointer">{{
                  deviceChild.deviceSum || "0"
                }}</big>{{ $t("home.top.bench") }}</span>
      </div>
      <div class="strip">
        <div class="offline" @click.stop="goOffline" >
          <el-progress :percentage="100" :show-text="false" color="#D7E7F7"></el-progress>
        </div>
        <div  class="online" @click.stop="goOnline" :style="{
                  width:
                    (deviceChild.on_lineDeviceCount / deviceChild.deviceSum) *
                      100 +
                    '%'
                }">
          <el-progress :percentage="100" :show-text="false" color="#00CF00"></el-progress>
        </div>
      </div>
      <div class="percenText">
        <span class="green" @click.stop="goOnline">{{ $t("home.top.on_line") }}
          {{ deviceChild.on_lineDeviceCount || "0" }}</span><span style="color: #9b9b9b" @click.stop="goOffline">{{ $t("home.top.off_line") }}
          {{ deviceChild.off_lineDeviceCount || "0" }}</span>
      </div>
    </div>
    <div class="equipmentPic" @click="goFacilityList" style="cursor: pointer">
      <img class="image" src="../../../../images/home/device.png" @click="goFacilityList" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deviceChild: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      saveAllList: [] //批量vuex中保存的菜单数组
    }
  },
  mounted() {
    setTimeout(() => {
      this.saveAllList = [...this.$store.state.selectList]
    }, 800)
  },
  methods: {
    //点击跳转到设备列表
    goFacilityList() {
      //判断一级 设备管理 名称是否能找到
      let DeviceName = this.$store.state.selectList.find(item=>item.name=="DeviceManage")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(DeviceName?.name){
        DeviceName.children.forEach((element) => {
          if (element.name.indexOf('Device') != -1) {
            this.$router.push({
                    name: 'Device',
                    jumpPageName:'SourceHome',//用于跳转后页面判断
                  })
          } else {
            return false
          }
        })
      }
    },
    //点击离线进度条  //0离线 1在线
    goOffline() {
      //判断一级 设备管理 名称是否能找到
      let DeviceName = this.$store.state.selectList.find(item=>item.name=="DeviceManage")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(DeviceName?.name){
        DeviceName.children.forEach((element) => {
          if (element.name.indexOf('Device') != -1) {
            this.$router.push({
              name: 'Device',
              query: {
                conStatus: '0',
                jumpPageName:'SourceHome',//用于跳转后页面判断
              }
            })
          } else {
            return false
          }
        })
      }
    },
    //点击在线进度条  //0离线 1在线
    goOnline() {
      //判断一级 设备管理 名称是否能找到
      let DeviceName = this.$store.state.selectList.find(item=>item.name=="DeviceManage")
      // let DeviceName = phoneName.find(item=>item.name=="Device")
      if(DeviceName?.name){
        DeviceName.children.forEach((element) => {
          if (element.name.indexOf('Device') != -1) {
            this.$router.push({
              name: 'Device',
              query: {
                conStatus: '1',
                jumpPageName:'SourceHome',//用于跳转后页面判断
              }
            })
          } else {
            return false
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.equipmentText {
  width: 70%;
  .title {
    height: 25%;
  }
  .strip {
    height: 10%;
    margin: 1.375rem 0rem !important;
    width: 100%;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    position: relative;
    .online {
      height: 0.375rem;
      position: absolute;
      top: 0rem;
      left: 0rem;
      cursor: pointer
    }
    .outService {
      position: absolute;
      top: 0rem;
      left: 0rem;
      z-index: inherit;
    }
    .offline{
      cursor: pointer
    }
  }
  .green {
    color: #00cf00;
  }

  .blue {
    color: #3e81e5;
  }

  // @media screen and (max-width: 1200px)  {
  //   //卡片总数展示
  //   .titles {
  //     font-size: 14px;
  //     color: #8a909e;
  //     margin-right: 20px;
  //     cursor: pointer;
  //   }
  //   .percenText {
  //     height: 25%;
  //     span {
  //       font-size: 12px;
  //     }
  //     span:hover {
  //       cursor: pointer;
  //     }
  //     display: flex;
  //     justify-content: space-between;
  //   }
  // }
}
//1920分辨率
@media screen and (min-width: 1920px) and (max-width: 2040px) {
  // 设备总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 3%;
  }
  // 设备 数 字体大小
  .zSize {
    font-size: 22px;
  }
  // 默认单位18
  .unit {
    font-size: 18px;
  }
  .titles {
    font-size: 20px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  .percenText {
    height: 25%;
    span {
      font-size: 1rem;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
//1680分辨率
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  // 设备总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 3%;
  }
  // 设备 数 字体大小
  .zSize {
    font-size: 22px;
  }
  // 默认单位18
  .unit {
    font-size: 18px;
  }
  .titles {
    font-size: 18px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  .percenText {
    height: 25%;
    span {
      font-size: 1rem;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
//2560分辨率
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 设备总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 5%;
    margin-left: 2%;
  }
  // 设备 数 字体大小
  .zSize {
    font-size: 30px;
  }
  // 设备总数  字体大小
  .titles {
    font-size: 26px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  // 默认单位22
  .unit {
    font-size: 18px;
  }
  // 图表 间隙 占比
  .equipmentPic {
    margin-right: 5%;
  }
  // 图片大小 占比
  .image {
    margin-top: 27%;
    width: 127%;
  }
  .percenText {
    height: 25%;
    span {
      // 在线离线字体大小
      font-size: 20px;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
//1680分辨率
@media screen and (max-width: 1679px) {
  // 设备总数  占比
  .equipmentText {
    width: 70%;
    margin-top: 3%;
  }
  // 设备 数 字体大小
  .zSize {
    font-size: 22px;
  }
  // 默认单位18
  .unit {
    font-size: 18px;
  }
  .titles {
    font-size: 18px;
    color: #8a909e;
    margin-right: 20px;
    cursor: pointer;
  }
  .percenText {
    height: 25%;
    span {
      font-size: 1rem;
    }
    span:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
  }
}
</style>
