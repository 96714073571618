<template>
  <div class="grid-content">
    <div class="title" style="color: #8a909e">
      <div class="titleFlog"></div>
      {{ $t("home.third.CompStatus") }}
    </div>
    <div class="disContanier">
      <!-- 图表 -->
      <div id="compliance">
        <div
          class="compliance"
          v-if="!showFlag1"
          style="width: 100%; height: 100%"
        >
          <div id="comStatus" style="width: 100%; height: 100%"></div>
        </div>
        <div v-else-if="showFlag1" class="noData">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
export default {
  data() {
    return {
      showFlag1: false,
      // 合规状态分布
      compliance_status: [],
      saveAllList: [], //批量vuex中保存的菜单数组
    };
  },
  watch: {
    "$i18n.locale"(newVal,old) {
      console.log(newVal,old,'val,old');
      // this.CurLangMode=newVal
      //监听语言的变化，每次变动都要重新渲染图表
      this.getCompState();
    },
  },
  mounted() {
    setTimeout(() => {
      this.getCompState();
      this.saveAllList = [...this.$store.state.selectList];
    }, 800);
  },
  methods: {
    //获取合规状态分布
    async getCompState() {
      var params;
      params = {
        groupID: "1",
      };
      const res = await this.$axios.post(
        "/httpServe/getCompState",
        params,
        true
      );
      // console.log(res.data, "合规分布");
      if (res.data.normalCount == null && res.data.violationCount == null) {
        this.showFlag1 = true;
      } else {
        this.showFlag1 = false;
      }
      this.compliance_status = [
        {
          value: res.data.normalCount,
          name: this.$t("home.third.normalEquipment"),
        }, //正常设备
        {
          value: res.data.violationCount,
          name: this.$t("home.third.violatingEquipment"),
        }, //违规设备
      ];

      this.comStatus(this.compliance_status);

      // // console.log(res.data, "获取合规状态分布数据");
    },
    //第二个合规状态分布图
    comStatus(compliance) {
      var chartDom = document.getElementById("comStatus");
      var myChart = echarts.init(chartDom);
      var that = this;
      var option;
      option = {
        color: ["#3E6EF1", "#47BDFE"],
        tooltip: {
          trigger: "item",
          show: false,
        },
        legend: {
          // 图例列表的布局朝向
          orient: "vertical",
          right: 2,
          top: 0,
          icon: "circle",
          itemWidth: 5,
          itemHeight: 5,
          width: "30%", //图例组件宽度
          tooltip: {
            show: true,
          },
          // padding: [50, 1], //调节legend的位置
          textStyle: {
            color: ["#3E6EF1", "#47BDFE"],
            fontSize: 11, //文字的字体大小
          },
          // 重写legend显示样式
          formatter: function (name) {
            // 获取legend显示内容
            let data = option.series[0].data;
            let total = 0;
            let tarValue = 0;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            let p = isNaN((tarValue / total) * 100)
              ? "0"
              : ((tarValue / total) * 100).toFixed(2);
            // return name + ' ' + ' ' + p + '%' + ' ' + tarValue + '(台)'
            return name;
            // + ' ' + tarValue + '台'
          },
          data: this.compliance_status.name,
        },
        series: [
          {
            type: "pie",
            // 图表旋转
            startAngle: 180,
            radius: ["30%", "50%"], // 设置环形饼状图， 第一个百分数设置内圈大小，第二个百分数设置外圈大小
            center: ["90%", "55%"], // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
            // hoverAnimation: true,
            avoidLabelOverlap: true,
            label: {
              show: true,
              // formatter: '{b}\n{c}台\n{d}% '
              formatter: "{b}\n{c}" + this.$t("public.Tower") + "\n{d}% ",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            //视觉引导线
            labelLine: {
              show: true,
              // length: 1 // 修改引导线第一段的长度
            },
            areaStyle: {
              normal: {
                // color: "#EAEAF0", //改变区域颜色
                position: "center",
                formatter: "{c}", // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容
              },
            },

            right: "50%",
            data: compliance,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      function eConsole(param) {
        if (param.name == "违规设备") {
          //判断一级 设备管理 名称是否能找到
          let DeviceName = that.$store.state.selectList.find(
            (item) => item.name == "DeviceManage"
          );
          // let DeviceName = phoneName.find(item=>item.name=="Device")
          if (DeviceName?.name) {
            DeviceName.children.forEach((element, index) => {
              console.log(index, "违规设备--------------------");
              if (element.name.indexOf("Device") != -1) {
                that.$router.push({
                  name: "Device",
                  query: {
                    key: index + "",
                    keyPath: ["2"],
                    isViolation: "0",
                    jumpPageName: "SourceHome", //用于跳转后页面判断
                  },
                });
              } else {
                return false;
              }
            });
          }

          // that.saveAllList.forEach((element) => {
          //   if (element.name.indexOf('DeviceManage') != -1) {
          //     that.$router.push({
          //       name: 'Device',
          //       query: {
          //         isViolation: '0'
          //       }
          //     })
          //   } else {
          //     return false
          //   }
          // })
        } else if (param.name == "正常设备") {
          //判断一级 设备管理 名称是否能找到
          let DeviceName = that.$store.state.selectList.find(
            (item) => item.name == "DeviceManage"
          );
          // let DeviceName = phoneName.find(item=>item.name=="Device")
          if (DeviceName?.name) {
            DeviceName.children.forEach((element, index) => {
              if (element.name.indexOf("Device") != -1) {
                that.$router.push({
                  name: "Device",
                  query: {
                    key: index + "",
                    // keyPath: ["2"],
                    isViolation: "1",
                    jumpPageName: "SourceHome", //用于跳转后页面判断
                  },
                });
              } else {
                return false;
              }
            });
          }

          // that.saveAllList.forEach((element) => {
          //   if (element.name.indexOf('DeviceManage') != -1) {
          //     that.$router.push({
          //       name: 'Device',
          //       query: {
          //         isViolation: '1'
          //       }
          //     })
          //   } else {
          //     return false
          //   }
          // })
        }
      }
      myChart.on("click", eConsole);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: 12%;
  font-size: 20.8px; //卡片title

  .titleFlog {
    display: inline-block;
    width: 3px;
    height: 20px;
    background: #4669cd;
  }
}

.disContanier {
  height: 80%;
  line-height: 10px;

  #compliance {
    width: 100%;
    height: 100%;

    .compliance {
      width: 100%;
      height: 100%;
    }
  }

  .noData {
    font-family: "Microsoft YaHei";
    font-weight: bold;
    font-size: 23px;
    color: #9d9d9d;
    text-align: center;
    line-height: 230px;
  }

  .complianceText {
    width: 60%;
    font-size: 0.375rem;

    .normal {
      margin: 3.625rem 0rem;

      span {
        color: #3c6cfa;
        margin: 0rem 0.5rem;
      }
    }

    .illegal {
      margin: 1.25rem 0rem;

      span {
        color: #8e9dc6;
        margin: 0rem 0.5rem;
      }
    }
  }
}

@media screen and (min-width: 2560px) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 24px; //卡片title
    //卡片title 距离外层百分比
    margin-top: 1%;

    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 2559px) and (-webkit-min-device-pixel-ratio: 0) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title

    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
}

@media screen and (min-width: 1680px) and (max-width: 1919px) and (-webkit-min-device-pixel-ratio: 0) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 20.8px; //卡片title

    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
}

@media screen and (max-width: 1679px) and (-webkit-min-device-pixel-ratio: 0) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 18px; //卡片title

    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1220px) and (-webkit-min-device-pixel-ratio: 0) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 18px; //卡片title

    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
}

@media screen and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 0) {
  .title {
    width: 100%;
    height: 12%;
    font-size: 18px; //卡片title

    .titleFlog {
      display: inline-block;
      width: 3px;
      height: 20px;
      background: #4669cd;
    }
  }
}
</style>
