<template>
  <div v-if="routeName == 'SourceHome'" class="sourceHome" style="z-index: 1000">
    <el-row :gutter="20" class="numStat" style="  height: 20%;">
      <el-col :span="8" class="numStatBox" style="  height: 100%;">
        <devSum :deviceChild="deviceSumObj"> </devSum>
      </el-col>
      <el-col :span="8" class="numStatBox" style="  height: 100%;">
        <user_Sum :userChild="userSumObj"></user_Sum>
      </el-col>
      <el-col :span="8" class="numStatBox" style="  height: 100%;">
        <violationSum :violationChild="countingInfo"></violationSum>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="trend" style="  height: 35%;margin-top: 20px;margin-bottom: 20px;">
      <el-col :span=" 6" style="  height: 100%;">

        <systemUsage></systemUsage>
      </el-col>
      <!-- 活动趋势 -->
      <el-col :span="12" style="  height: 100%;">
        <deviceActivityTrend></deviceActivityTrend>

      </el-col>
      <!-- 统计 -->
      <el-col :span="6" style="  height: 100%;">

        <platformContentStatistics></platformContentStatistics>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="  height: 35%;" class="distribute">
      <el-col :span="6" style="  height: 100%;">
        <!-- v-if="!showFlag1" -->

        <complianceStatus></complianceStatus>
      </el-col>

      <el-col :span="12" style="  height: 100%;">
        <trendViolations></trendViolations>
      </el-col>
      <el-col :span="6" style="  height: 100%;">
        <distributionViolations></distributionViolations>
      </el-col>
    </el-row>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose1="handleClose1" @determine1="determine1" :useInfo="useInfo" :serveInfo="serveInfo" :width="'240px'" />
  </div>
</template>
<script>
import moment from 'moment'
import * as echarts from 'echarts'
import locale from '../../langs/locale'
import Eldialog from '@/components/elDialog' // 详情
import devSum from './components/top/deviceSum.vue' //设备总数
import user_Sum from './components/top/userSum.vue' //用户总数
import dialogInfo from '@/components/activateMessage/index'
import violationSum from './components/top/violationSum.vue' //违规总数
import systemUsage from './components/center/systemUsage.vue' //系统使用情况
import trendViolations from './components/bottom/trendViolations.vue' //违规行为趋势
import complianceStatus from './components/bottom/complianceStatus.vue' //合规状态分布
import deviceActivityTrend from './components/center/deviceActivityTrend.vue' //设备活跃趋势
import platformContentStatistics from './components/center/platformContentStatistics.vue' //平台内容统计
import distributionViolations from './components/bottom/distributionViolations.vue'
export default {
  components: {
    devSum,
    user_Sum,
    Eldialog,
    dialogInfo,
    systemUsage,
    violationSum,
    trendViolations,
    complianceStatus,
    deviceActivityTrend,
    distributionViolations,
    platformContentStatistics
  },
  data() {
    return {
      sum: '',
      useInfo: '', //提示框中使用期限剩余提示语
      startTime: '',
      routeName:'',
      serveInfo: '', // 提示框中服务期限剩余提示语
      userSumObj: {}, //用户计数信息
      checkRadio: '', //选中的违规信息
      startTime0: '', //违规行为时间
      saveAllList: [], //批量vuex中保存的菜单数组
      systemUsage: {}, //系统使用情况
      countingInfo: {}, //设备计数信息
      deviceSumObj: {}, //违规计数信息
      visibaelFlag: false //提示消息默认--关闭
    }
  },
  computed: {},
  created() {
    setTimeout(()=>{
      this.routeName = this.$route.name
    },1000)
    
  },
  mounted() {
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    setTimeout(() => {
      loading.close()
    }, 1000)
    var enttime = moment().format('YYYY-MM-DD')
    var statiem = moment().subtract(6, 'days').format('YYYY-MM-DD')
    this.getdiffdate(statiem, enttime)
    this.getNbRot() // 获取设备计数信息，用户计数信息，违规计数信息
    setTimeout(() => {
      this.saveAllList = [...this.$store.state.selectList]
    }, 800)
  },
  methods: {
    //相关激活信息弹框

    // async getActiveInfo() {
    //   var a = 0;
    //   var params = {};
    //   const res = await this.$axios.post(
    //     "/api/product/getLicInfo.do",
    //     params,
    //     true
    //   );
    //   // // console.log(res.data, "激活信息");
    //   this.visibaelFlag = true;
    //   this.useInfo = "使用期限剩余：" + res.data.leftDays + "天";
    //   this.serveInfo = "服务期限剩余：" + res.data.leftServiceDays + "天";
    //   a = 1;
    //   sessionStorage.setItem("a", 1);
    // },
    handleClose1() {
      // console.log('guanibi')
      this.visibaelFlag = false //提示消息  关闭
    },
    //确定回调事件
    determine1() {
      this.visibaelFlag = false //提示消息  关闭
    },
    //获取两日期之间日期列表函数
    getdiffdate(stime, etime) {
      //初始化日期列表，数组
      var diffdate = new Array()
      var i = 0
      //开始日期小于等于结束日期,并循环
      while (stime <= etime) {
        diffdate[i] = stime
        //获取开始日期时间戳
        var stime_ts = new Date(stime).getTime()
        //增加一天时间戳后的日期
        var next_date = stime_ts + 24 * 60 * 60 * 1000
        //拼接年月日，这里的月份会返回（0-11），所以要+1
        var next_dates_y = new Date(next_date).getFullYear() + '-'
        var next_dates_m =
          new Date(next_date).getMonth() + 1 < 10
            ? '0' + (new Date(next_date).getMonth() + 1) + '-'
            : new Date(next_date).getMonth() + 1 + '-'
        var next_dates_d =
          new Date(next_date).getDate() < 10
            ? '0' + new Date(next_date).getDate()
            : new Date(next_date).getDate()
        stime = next_dates_y + next_dates_m + next_dates_d
        //增加数组key
        i++
      }
      this.dateGrop = diffdate
    },
    // 获取设备计数信息，用户计数信息，违规计数信息
    async getNbRot() {
      let roleId = sessionStorage.getItem('roleId')
      if (roleId == 4) {
           return
        }else{
          var param = {
        groupID: '1'
      }
      const res = await this.$axios.post('/httpServe/getCountInfo', param, true)
      this.countingInfo = res.data
      this.deviceSumObj = res.data
      this.userSumObj = res.data
      this.startTime0 = res.data.startTime
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sourceHome {
  width: 100%;
  height: 100%;
  padding: 20px 20px 0px 20px;

  background: #eaf3fc;
}
.grid-content {
  width: 100%;
  height: 100%;
  .noData {
    font-family: 'Microsoft YaHei';
    font-weight: bold;
    font-size: 23px;
    color: #9d9d9d;
    text-align: center;
    line-height: 230px;
  }
}
.numStat,
.trend,
.distribute {
  box-sizing: border-box;
  .el-col {
    box-sizing: border-box;
    height: 100%;
    .grid-content {
      border-radius: 0.625rem;
      background: #fff;
      height: 100%;
      padding: 0.625rem 1.25rem;
    }
  }
}
.numStat {
  .el-col {
    .grid-content {
      height: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
.title {
  text-align: left;
}
.equipmentText {
  width: 70%;
  .title {
    height: 25%;
  }
  .strip {
    height: 10%;
    margin: 1.375rem 0rem !important;
    width: 100%;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    position: relative;
    .online {
      height: 0.375rem;
      position: absolute;
      top: 0rem;
      left: 0rem;
    }
    .outService {
      position: absolute;
      top: 0rem;
      left: 0rem;
      z-index: inherit;
    }
  }
}
.percenText {
  height: 25%;
  span {
    font-size: 1rem;
  }
  span:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: space-between;
}
.green {
  color: #00cf00;
}

.blue {
  color: #3e81e5;
}
.titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// 系统
.trend {
  .el-col {
    .grid-content {
      height: 100%;
    }
  }
}
// 分布
.distribute {
  .el-col {
    .grid-content {
      height: 100%;
      .title {
        width: 100%;
        height: 12%;
        font-size: 1.3rem;
      }
    }
  }
}

//鼠标悬浮样式
.el-progress:hover {
  cursor: pointer;
}
.distribute .el-col .grid-content .viola #violaFound {
  width: 100% !important;
  canvas {
    width: 100% !important;
  }
}
</style>
